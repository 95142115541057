import { Expose, Transform, Type } from 'class-transformer'
import moment from 'moment'
import { oc } from 'ts-optchain'
import { useNormalizedValue } from '../../hooks/useNormalizeValue'
import { IClient } from '../../interfaces/IClient'
import { IBudget } from '../../interfaces/IBudget'
import { IFarm } from '../../interfaces/IFarm'
import { IHarvest } from '../../interfaces/IHarvest'
import { UploadedFile } from '../../shared/serverApi/uploadApi'
import {
  toOrderItemClass,
  toOrderItemsPlain
} from '../../shared/transformerHelpers'
import { Order } from '../Order/Order'
import { OrderSimulationBilling } from './OrderSimulationBilling'
import { OrderSimulationDeadlines } from './OrderSimulationDeadlines'
import { OrderItem } from './OrderSimulationItem'

export interface OrderItems {
  [index: string]: OrderItem
}

export interface OrderItemsPlain {
  [index: string]: any
}

/**
 * Contém dados de uma simulação de um pedido
 */
export class OrderSimulation {
  @Expose()
  pedidoReferencia?: Order

  @Expose()
  orcamentoReferencia?: IBudget

  @Expose()
  hasShipping = false

  @Expose()
  hasBuyerShipping?: boolean

  @Expose()
  shippingCost = 0

  @Expose()
  categoryCultive: number = 10

  @Expose()
  hasShippingError = false

  @Type(() => OrderSimulationDeadlines)
  deadlines: OrderSimulationDeadlines = new OrderSimulationDeadlines()

  @Expose()
  @Transform(toOrderItemClass, { toClassOnly: true })
  @Transform(toOrderItemsPlain, { toPlainOnly: true })
  items: OrderItems = {}

  @Type(() => OrderSimulationBilling)
  billing = new OrderSimulationBilling()

  @Expose()
  observations = ''

  @Expose()
  salesType = 'Venda'

  @Expose()
  farm?: IFarm

  @Expose()
  farmId?: number

  @Expose()
  farmUseCnpj = false

  @Expose()
  barterTax?: number

  @Expose()
  bagValue?: number

  @Expose()
  farmRegionId?: number

  @Expose()
  harvestId?: string

  @Expose()
  statusTSI?: string

  @Expose()
  professionalId?: number

  @Expose()
  harvest?: IHarvest

  @Expose()
  client?: IClient

  @Expose()
  clientId?: number

  @Expose()
  clientSyngentaClassification?: string

  @Expose()
  uploads: UploadedFile[] = []

  /**
   * Retorna valor final do germoplasma para pedido simulado.
   */
  get sacksTotal() {
    const items = Object.values(this.items)

    const total = items.reduce((total, item) => {
      return total + item.calculatedSacks
    }, 0)

    return useNormalizedValue(total)
  }

  /**
   * Retorna valor final do germoplasma para pedido simulado.
   */
  get areaTotal() {
    const items = Object.values(this.items)

    const total = items.reduce((total, item) => {
      return total + item.inputedArea
    }, 0)

    return useNormalizedValue(total)
  }

  /**
   * Retorna valor final do germoplasma para pedido simulado.
   */
  get seedTotal() {
    const items = Object.values(this.items)

    const total = items.reduce((total, item) => {
      return total + item.calculatedSacks * item.pricing.calculatedPrice
    }, 0)

    return useNormalizedValue(total)
  }

  /**
   * Retorna valor final dos tratamentos para pedido simulado.
   */
  get treatmentTotal() {
    const items = Object.values(this.items)

    const total = items.reduce((total, item) => {
      const treatmentCostPerSackWithDiscount = item.treatmentDiscount
        ? item.treatmentDiscount.valorDescontadoSacaPadrao
        : 0
      return total + treatmentCostPerSackWithDiscount * item.calculatedSacks
    }, 0)

    return useNormalizedValue(total)
  }

  /**
   * Retorna valor final dos royalties de tecnologia para pedido simulado.
   */
  get royaltiesTotal() {
    const items = Object.values(this.items)

    const total = items.reduce((total, item) => {
      return total + oc(item).calculatedRoyalties(0)
    }, 0)

    return useNormalizedValue(total)
  }

  /**
   * Retorna valor final do frote para pedido simulado.
   */
  get shippingTotal() {
    const items = Object.values(this.items)

    const total = items.reduce((total, item) => {
      return total + item.calculatedSacks * this.shippingCost
    }, 0)

    return useNormalizedValue(total)
  }

  get discountTotal() {
    const seedTotalWithDiscount = this.seedTotal
    const items = Object.values(this.items)

    let seedTotalWithoutDiscount = 0
    let treatmentTotalWithDiscount = 0
    let treatmentTotalWithoutDiscount = 0

    items.forEach(item => {
      const itemChain = oc(item)
      seedTotalWithoutDiscount += item.calculatedSacks * item.pricing.basePrice

      treatmentTotalWithoutDiscount +=
        item.calculatedSacks * itemChain.treatmentPrice.valorSacaPadrao(0)

      treatmentTotalWithDiscount +=
        item.calculatedSacks *
        itemChain.treatmentDiscount.valorDescontadoSacaPadrao(0)
    }, 0)

    const totalDiscountTreatment =
      treatmentTotalWithoutDiscount - treatmentTotalWithDiscount

    const total =
      seedTotalWithoutDiscount - seedTotalWithDiscount + totalDiscountTreatment

    return useNormalizedValue(total)
  }

  get total() {
    const total =
      this.seedTotal +
      this.treatmentTotal +
      this.royaltiesTotal +
      this.shippingTotal

    return useNormalizedValue(total)
  }

  get isBarter() {
    return this.salesType === 'Barter'
  }

  get totalBarter() {
    const tax = this.barterTax ? this.barterTax : 0
    const taxOperation = 1 - tax

    return useNormalizedValue(this.total / taxOperation)
  }

  get totalBarterBags() {
    const valorBag = this.bagValue ? this.bagValue : 0

    const bags = this.totalBarter / valorBag

    return useNormalizedValue(bags, 0)
  }

  get hasErrors() {
    const itemsHasErrors = Object.values(this.items).reduce(
      (hasErrors, item) => {
        return hasErrors || item.hasError
      },
      false
    )

    return (
      this.billing.hasErrors(this) ||
      itemsHasErrors ||
      (this.hasShipping && this.hasShippingError) ||
      !this.deadlines.isAllDeadlinesSetup ||
      // (this.isInHarvestTerm && this.uploads.length === 0) ||
      !this.clientId ||
      !this.farmId ||
      !this.harvest ||
      !this.professionalId ||
      (this.salesType === 'Barter' && !this.barterTax)
    )
  }

  get isInHarvestTerm() {
    if (
      !this.harvest ||
      !this.deadlines.seed ||
      !this.deadlines.royalties ||
      !this.deadlines.treatment
    )
      return false

    const starOfNovember = moment({ year: this.harvest.anoColheita, month: 10 })

    const anyInHarvestTerm =
      starOfNovember.isSameOrBefore(this.deadlines.seed) ||
      starOfNovember.isSameOrBefore(this.deadlines.royalties) ||
      starOfNovember.isSameOrBefore(this.deadlines.treatment)

    return anyInHarvestTerm
  }
}
