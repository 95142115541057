import jwtDecode from 'jwt-decode'
import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { ITokenPayload } from '../interfaces/ITokenPayload'
import { ApiError } from '../shared/ApiError'
import permissions, {
  CommonKeys,
  GlobalPermissions
} from '../shared/permissions'
import { tryLogin, tryRefreshToken } from '../shared/ServerApi'
import { message } from 'antd'

// tslint:disable no-console

export interface IAuthPayload {
  name: ''
  email: ''
  expiresAt: Date
}

export enum AuthStatus {
  isAuth = 'isAuth', // Usuário autenticado
  isGuest = 'isGuest' // Usuário não atenticado
}

export interface IAuthError {
  message: string
}

export class AuthStore {
  @observable
  status: AuthStatus = AuthStatus.isGuest

  @observable
  isLoading = false

  @observable
  hasError = false

  @observable
  tokenPayload?: ITokenPayload

  @observable
  token?: string

  @observable
  error?: ApiError

  @computed
  get isExpired() {
    return this.tokenPayload ? this.isPasswordExpired(this.tokenPayload) : false
  }

  @computed
  get isAuth() {
    return this.status === AuthStatus.isAuth
  }

  @computed
  get isGuest() {
    return this.status === AuthStatus.isGuest
  }

  get isSalesRep() {
    return (
      this.isAuth &&
      !!this.tokenPayload &&
      this.tokenPayload.role === 'RepresentanteVenda'
    )
  }

  get isAgent() {
    return (
      this.isAuth && !!this.tokenPayload && this.tokenPayload.role === 'Agente'
    )
  }

  get isManager() {
    return (
      this.isAuth && !!this.tokenPayload && this.tokenPayload.role === 'Gerente'
    )
  }

  get isFinanceiro() {
    return (
      this.isAuth &&
      !!this.tokenPayload &&
      this.tokenPayload.role === 'Financeiro'
    )
  }

  get isAnalistaFinanceiro() {
    return (
      this.isAuth &&
      !!this.tokenPayload &&
      this.tokenPayload.role === 'AnalistaFinanceiro'
    )
  }

  get isAnalistaFinanceiroComLimite() {
    return (
      this.isAuth &&
      !!this.tokenPayload &&
      this.tokenPayload.role === 'AnalistaFinanceiroComLimite'
    )
  }

  get isDiretor() {
    return (
      this.isAuth && !!this.tokenPayload && this.tokenPayload.role === 'Diretor'
    )
  }

  get isInteligenciaMercado() {
    return (
      this.isAuth &&
      !!this.tokenPayload &&
      this.tokenPayload.role === 'InteligenciaMercado'
    )
  }

  get isSuperintendente() {
    return (
      this.isAuth &&
      !!this.tokenPayload &&
      this.tokenPayload.role === 'Superintendente'
    )
  }

  get isAdministracaoVendas() {
    return (
      this.isAuth &&
      !!this.tokenPayload &&
      this.tokenPayload.role === 'AdministracaoVendas'
    )
  }

  get isGerenteAdministracaoVendas() {
    return (
      this.isAuth &&
      !!this.tokenPayload &&
      this.tokenPayload.role === 'GerenteAdministracaoVendas'
    )
  }

  get isAdministrador() {
    return (
      this.isAuth &&
      !!this.tokenPayload &&
      this.tokenPayload.role === 'Administrador'
    )
  }

  get onlyViewer() {
    return (
      this.isSalesRep ||
      this.isFinanceiro ||
      this.isAnalistaFinanceiroComLimite ||
      this.isAnalistaFinanceiro
    )
  }

  get professionalId() {
    return this.tokenPayload && this.tokenPayload.profissional_id
  }

  @action.bound
  async login(username: string, pasword: string, recaptcha: string) {
    try {
      this.isLoading = true
      // eslint-disable-next-line @typescript-eslint/camelcase
      const { token, refreshToken } = await tryLogin(
        username,
        pasword,
        recaptcha
      )

      const payload = this.decodeToken(token)

      if (this.validateToken(payload))
        this.setSuccessful(payload, token, refreshToken)
      else throw new ApiError('Falha na autenticação, token expirada.', 400, {})

      const daysLeft = this.getPasswordTimeLeft(payload)
      if (daysLeft <= 5 && daysLeft > 0) {
        message.warning(
          `Sua senha expira em ${daysLeft} dia${daysLeft > 1 ? 's' : ''}. 
          Por favor, altere sua senha na página "Editar Perfil".`,
          10
        )
      }
    } catch (e) {
      this.hasError = true
      this.isLoading = false
      if (e instanceof ApiError) this.error = e
    }
  }

  @action.bound
  async restoreAuthentication() {
    this.isLoading = true
    const token = localStorage.getItem('auth_token')

    if (!token) {
      this.status = AuthStatus.isGuest
      this.isLoading = false

      return
    }

    const payload = this.decodeToken(token)

    if (this.validateToken(payload)) return this.setSuccessful(payload, token)

    this.status = AuthStatus.isGuest
    this.isLoading = false
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/camelcase
  async refreshToken(refresh_token: string) {
    try {
      this.isLoading = true
      // eslint-disable-next-line @typescript-eslint/camelcase
      const { token, refreshToken } = await tryRefreshToken(refresh_token)
      const payload = this.decodeToken(token)

      if (this.validateToken(payload))
        return this.setSuccessful(payload, token, refreshToken)

      this.isLoading = false
      this.hasError = false
    } catch (e) {
      console.info(
        'Não foi possível realizar o refresh da token de autenticação.'
      )
    }
    this.status = AuthStatus.isGuest
    this.isLoading = false
  }

  @action.bound
  logout() {
    localStorage.removeItem('auth_token')
    localStorage.removeItem('refreshToken')
    this.status = AuthStatus.isGuest
    this.token = undefined
    this.tokenPayload = undefined
    this.error = undefined
  }

  @action.bound hasPerm(
    feature: keyof typeof GlobalPermissions,
    policy: CommonKeys
  ) {
    if (!this.tokenPayload || !this.tokenPayload.role) return false
    const loggedRole = this.tokenPayload.role

    return (
      !!permissions[loggedRole] &&
      !!permissions[loggedRole][feature] &&
      !!permissions[loggedRole][feature].includes(policy)
    )
  }

  @action.bound
  private setSuccessful(
    payload: ITokenPayload,
    token: string,
    refreshToken?: string
  ) {
    this.status = AuthStatus.isAuth
    this.isLoading = false
    this.hasError = false
    this.token = token
    this.tokenPayload = payload
    localStorage.setItem('auth_token', token)
    if (refreshToken) localStorage.setItem('refreshToken', refreshToken)
  }

  private isPasswordExpired(payload: ITokenPayload) {
    const now = moment()
    const exp = moment(payload.ExpPassword, 'DD/MM/YYYY')
    return exp.isBefore(now, 'day')
  }

  private getPasswordTimeLeft(payload: ITokenPayload) {
    const now = moment()
    const exp = moment(payload.ExpPassword, 'DD/MM/YYYY')

    return exp.diff(now, 'days')
  }

  private validateToken(payload: ITokenPayload) {
    const now = moment()
    const exp = moment(payload.exp * 1000)

    return exp.isAfter(now)
  }

  private decodeToken(token: string) {
    const decodedToken: ITokenPayload = jwtDecode(token)

    return decodedToken
  }
}
