import { action, observable } from 'mobx'
import { ApiError } from '../shared/ApiError'
import { getClientUploads } from '../shared/ServerApi'
import { UploadedFile } from '../shared/serverApi/uploadApi'

export class DocumentStore {
  @observable page?: number
  @observable pageSize?: number
  @observable total = 0

  @observable isLoading = false
  @observable hasError = false
  @observable error?: ApiError

  @observable documents: UploadedFile[] = []

  @observable descricao?: string
  @observable uploaderId?: number

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound setDescricao(descricao?: string) {
    this.descricao = descricao
  }

  @action.bound setUploaderId(uploaderId?: number) {
    this.uploaderId = uploaderId
  }

  @action.bound async fetchClientDocuments(
    clientId: number,
    tipoUpload: string
  ): Promise<UploadedFile[] | ApiError> {
    this.isLoading = true
    this.hasError = false
    this.error = undefined
    this.documents = []

    try {
      const response = await getClientUploads(
        clientId,
        tipoUpload,
        this.page,
        this.pageSize,
        this.descricao,
        this.uploaderId
      )

      this.documents = response
      this.total = response.length
      return this.documents
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) {
        this.error = e
      }
      return e as ApiError
    } finally {
      this.isLoading = false
    }
  }
}
