import { message } from 'antd'
import { action, observable } from 'mobx'
import { IUser, IUserFormData } from '../interfaces/IUser'
import { ApiError } from '../shared/ApiError'
import {
  tryBlockUser,
  tryGetUser,
  tryPutUser,
  tryUnblockUser
} from '../shared/serverApi/userApi'

export class UserStore {
  @observable fetchingError?: ApiError
  @observable isFetchingSingleUser = false
  @observable hasErrorFetchingSingleUser = false

  @observable isSavingUser = false
  @observable hasErrorSavingUser = false
  @observable savingErrorUser?: ApiError

  @observable isDeletingUser = false

  // Items
  @observable user = {} as IUser

  // Store actions
  @action.bound async fetchUser(id: string) {
    try {
      if (!id) {
        this.user = {} as IUser
        return
      }
      this.isFetchingSingleUser = true
      const response = await tryGetUser(id)

      this.user = response
      this.fetchingError = undefined
      return this.user
    } catch (e) {
      this.hasErrorFetchingSingleUser = true
      if (e instanceof ApiError) this.fetchingError = e
      return e
    } finally {
      this.isFetchingSingleUser = false
    }
  }

  @action.bound async saveUser(data: IUserFormData, professionalId: number) {
    try {
      this.isSavingUser = true
      const response = await tryPutUser(data, professionalId)
      message.success('Usuário salvo com sucesso.')
      this.savingErrorUser = undefined
      return response
    } catch (e) {
      if (e instanceof ApiError) this.savingErrorUser = e
      return undefined
    } finally {
      this.isSavingUser = false
    }
  }

  @action.bound async blockUser(id: string) {
    try {
      this.isDeletingUser = true
      await tryBlockUser(id)
      message.success('Usuário bloqueado com sucesso.')
    } catch (e) {
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isDeletingUser = false
    }
  }

  @action.bound async unblockUser(id: string) {
    try {
      this.isDeletingUser = true
      await tryUnblockUser(id)
      message.success('Usuário desbloqueado com sucesso.')
    } catch (e) {
      if (e instanceof ApiError) this.fetchingError = e
    } finally {
      this.isDeletingUser = false
    }
  }
}
