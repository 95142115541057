export const Gerente = {
  //Novos
  carteira: ['visualizar', 'gerenciar'],
  tsiAssociation: ['visualizar'],
  precoSyngentaClass: ['visualizar'],
  precoSyngentaDesconto: ['visualizar'],
  cliente: ['visualizar'],
  estoqueCota: ['visualizar', 'editar'],
  barter: ['visualizar'],
  medidaIR: ['visualizar'],
  medidaPMS: ['visualizar'],
  medidaPOP: ['visualizar'],
  budget: ['visualizar', 'editar', 'deletar'], //orcamento
  pedido: [
    // Gerais
    'criar', // Simular
    'visualizar', // Gerenciar
    'cancelar', // Status até proposta
    'cancelarPedido', // Status todos status canceláveis
    'atualizarPrecoPedido', //Royalties
    'baixarDocumento',
    // Simulação
    'visualizarDocsCreditoSimulacao',
    'aprovarPrecoSimulacao',
    'baixarSimulacao',
    'gerarProposta',
    // Proposta
    'baixarProposta',
    'aprovarProposta',
    'estenderPrazoProposta',
    // Pedido - Contrato
    'gerarContrato',
    'baixarContrato',
    'aprovarContrato',
    'cancelarContrato',
    // Pedido - Pedido
    'baixarPedido',
    'finalizarPedido'
  ],
  period: ['criar', 'visualizar', 'editar', 'deletar'],
  precoDescontoPermitido: ['visualizar'],
  precoDescontoCampanha: ['visualizar'],
  precoFrete: ['visualizar'],
  precoTratamento: ['visualizar'],
  precoTecnologia: ['visualizar'],
  precoGermoplasma: ['visualizar'],
  sistemaProfissional: ['visualizar', 'desbloquear'],
  regiaoFrete: ['visualizar'],
  regiaoMonsanto: ['visualizar'],
  harvest: ['visualizar'],
  forecast: ['criar', 'visualizar', 'editar', 'deletar'] //Varredura
}

export const RepresentanteVenda = {
  estoqueCota: ['visualizar', 'editar'],
  cliente: ['visualizar'],
  budget: ['visualizar', 'editar', 'deletar'], //orcamento
  pedido: [
    // Gerais
    'criar', // Simular
    'cancelarPedidoSimulacao', // Status apenas da simulação
    'visualizar', // Gerenciar
    'atualizarPrecoPedido', //Royalties
    'baixarDocumento',
    // Simulação
    'visualizarDocsCreditoSimulacao',
    'baixarSimulacao',
    // Proposta
    'gerarProposta',
    'baixarProposta',
    'aprovarProposta',
    // Pedido - Contrato
    'gerarContrato',
    'baixarContrato',
    'cancelarContrato',
    // Pedido - Pedido
    'baixarPedido',
    'finalizarPedido'
  ],
  period: ['visualizar'],
  forecast: ['criar', 'visualizar', 'editar', 'deletar']
}

export const Agente = {
  pedido: [
    // Gerais
    'criar', // Simular
    'visualizar', // Gerenciar
    'atualizarPrecoPedido', //Royalties
    'baixarDocumento',
    // Simulação
    'baixarSimulacao',
    'gerarProposta',
    // Proposta
    'baixarProposta',
    'aprovarProposta',
    // 'estenderPrazoProposta',
    // Pedido - Contrato
    'gerarContrato',
    'baixarContrato',
    // Pedido - Pedido
    'baixarPedido'
  ],
  estoqueCota: ['visualizar']
}

export const Financeiro = {
  cliente: ['visualizar'],
  pedido: [
    // Gerais
    'visualizar', // Gerenciar
    'uploadDocumento',
    'baixarDocumento',
    // Simulação
    'visualizarDocsCreditoSimulacao',
    'aprovarCreditoSimulacao',
    'baixarSimulacao',
    // Proposta
    'baixarProposta',
    // Pedido - Contrato
    'baixarContrato',
    // Pedido - Pedido
    'baixarPedido'
  ]
  // tsi: ['criar', 'visualizar', 'deletar', 'editar']
}

export const AnalistaFinanceiro = {
  cliente: ['visualizar'],
  pedido: [
    // Gerais
    'visualizar', // Gerenciar
    'uploadDocumento',
    'baixarDocumento',
    // Simulação
    'aprovarCreditoSimulacao',
    'visualizarDocsCreditoSimulacao',
    'baixarSimulacao',
    // Proposta
    'baixarProposta',
    // Pedido - Contrato
    'baixarContrato',
    // Pedido - Pedido
    'baixarPedido'
  ]
}

export const AnalistaFinanceiroComLimite = {
  cliente: ['visualizar'],
  pedido: [
    // Gerais
    'visualizar', // Gerenciar
    'uploadDocumento',
    'baixarDocumento',
    // Simulação
    'visualizarDocsCreditoSimulacao',
    'aprovarCreditoSimulacao',
    'baixarSimulacao',
    // Proposta
    'baixarProposta',
    // Pedido - Contrato
    'baixarContrato',
    // Pedido - Pedido
    'baixarPedido'
  ]
}

export const Diretor = {
  cliente: ['visualizar'],
  pedido: [
    // Gerais
    'visualizar', // Gerenciar
    // Simulação
    'baixarSimulacao',
    'aprovarTipoDeVenda',
    // Proposta
    'baixarProposta',
    // Pedido - Contrato
    'baixarContrato',
    // Pedido - Pedido
    'baixarPedido',
    // Análise
    'visualizarDocsCreditoSimulacao',
    'aprovarCreditoSimulacao',
    'aprovarPrecoSimulacao'
  ],
  regiaoFrete: ['visualizar'],
  regiaoMonsanto: ['visualizar'],
  precoSyngentaClass: ['visualizar'],
  medidaIR: ['visualizar'],
  medidaPMS: ['visualizar'],
  medidaPOP: ['visualizar'],
  estoqueEstimativa: ['visualizar'],
  estoqueCota: ['visualizar'],
  sistemaProfissional: ['visualizar'],
  sistemaDocusign: ['visualizar']
  // tsi: ['criar', 'visualizar', 'deletar', 'editar']
}

export const Superintendente = {
  cliente: ['visualizar'],
  pedido: [
    // Gerais
    'criar', // Simular
    'visualizar', // Gerenciar
    'cancelar', // Status até proposta
    'cancelarPedido', // Status todos status canceláveis
    'atualizarPrecoPedido', //Royalties
    'uploadDocumento',
    'deletarDocumento',
    'baixarDocumento',
    // Simulação
    'visualizarDocsCreditoSimulacao',
    'aprovarCreditoSimulacao',
    'aprovarPrecoSimulacao',
    'baixarSimulacao',
    'gerarProposta',
    'aprovarTipoDeVenda',
    // Proposta
    'baixarProposta',
    'aprovarProposta',
    'estenderPrazoProposta',
    // Pedido - Contrato
    'gerarContrato',
    'baixarContrato',
    'aprovarContrato',
    'cancelarContrato',
    // Pedido - Pedido
    'baixarPedido',
    'finalizarPedido'
  ],
  budget: ['visualizar'],
  harvest: ['visualizar'],
  forecast: ['visualizar'],
  barter: ['criar', 'visualizar', 'editar', 'deletar'],
  period: ['criar', 'visualizar', 'editar', 'deletar'],
  regiaoFrete: ['visualizar'],
  regiaoMonsanto: ['visualizar'],
  carteira: ['visualizar', 'gerenciar'],
  precoTratamento: ['visualizar'],
  precoTecnologia: ['visualizar'],
  precoGermoplasma: ['visualizar'],
  precoFrete: ['visualizar'],
  precoDescontoPermitido: ['visualizar', 'editar'],
  precoDescontoCampanha: ['visualizar', 'editar'],
  precoSyngentaClass: ['criar', 'visualizar', 'deletar', 'editar'],
  precoSyngentaDesconto: ['visualizar', 'editar'],
  medidaIR: ['visualizar'],
  medidaPMS: ['visualizar'],
  medidaPOP: ['visualizar'],
  estoqueEstimativa: ['visualizar', 'editar'],
  estoqueCota: ['visualizar', 'editar'],
  estoqueCotaRegional: ['visualizar', 'editar'],
  sistemaProfissional: ['visualizar', 'desbloquear'],
  sistemaDocusign: ['visualizar'],
  // tsi: ['criar', 'visualizar', 'deletar', 'editar'],
  tsiAssociation: ['criar', 'visualizar', 'deletar', 'editar']
}

export const InteligenciaMercado = {
  cliente: ['visualizar'],
  pedido: [
    // Gerais
    'visualizar', // Gerenciar
    'baixarDocumento',
    // Simulação
    'visualizarDocsCreditoSimulacao',
    'baixarSimulacao',
    // Proposta
    'baixarProposta',
    // Pedido - Contrato
    'baixarContrato',
    // Pedido - Pedido
    'baixarPedido'
  ],
  budget: ['visualizar'],
  harvest: ['visualizar'],
  forecast: ['visualizar'],
  barter: ['visualizar'],
  period: ['visualizar'],
  regiaoFrete: ['visualizar'],
  regiaoMonsanto: ['visualizar'],
  carteira: ['visualizar', 'gerenciar'],
  precoTratamento: ['visualizar'],
  precoTecnologia: ['visualizar'],
  precoGermoplasma: ['visualizar'],
  precoFrete: ['visualizar'],
  precoDescontoPermitido: ['visualizar'],
  precoDescontoCampanha: ['visualizar'],
  precoSyngentaClass: ['visualizar'],
  precoSyngentaDesconto: ['visualizar'],
  medidaIR: ['visualizar'],
  medidaPMS: ['visualizar'],
  medidaPOP: ['visualizar'],
  estoqueEstimativa: ['visualizar'],
  estoqueCota: ['visualizar'],
  estoqueCotaRegional: ['visualizar'],
  sistemaProfissional: ['visualizar'],
  sistemaDocusign: ['visualizar'],
  tsi: ['visualizar'],
  tsiAssociation: ['visualizar']
}

export const AdministracaoVendas = {
  cliente: ['criar', 'visualizar', 'deletar', 'editar'],
  pedido: [
    // Gerais
    'visualizar',
    'uploadDocumento',
    'deletarDocumento',
    'baixarDocumento',
    // Simulação
    'visualizarDocsCreditoSimulacao',
    'baixarSimulacao',
    'gerarProposta',
    // Proposta
    'baixarProposta',
    // Pedido - Contrato
    'gerarContrato',
    'baixarContrato',
    'cancelarContrato',
    // Pedido - Pedido
    'baixarPedido',
    'finalizarPedido'
  ],
  budget: ['visualizar', 'editar', 'deletar'],
  harvest: ['criar', 'visualizar', 'editar'],
  forecast: ['criar', 'visualizar', 'editar', 'deletar'],
  barter: ['criar', 'visualizar', 'editar', 'deletar'],
  period: ['criar', 'visualizar', 'editar', 'deletar'],
  regiaoFrete: ['criar', 'visualizar', 'deletar', 'editar'],
  regiaoMonsanto: ['criar', 'visualizar', 'deletar', 'editar'],
  carteira: ['criar', 'visualizar', 'deletar', 'editar', 'gerenciar'],
  precoTratamento: ['visualizar', 'editar'],
  precoTecnologia: ['visualizar', 'editar'],
  precoGermoplasma: ['visualizar', 'editar'],
  precoFrete: ['visualizar', 'editar'],
  precoDescontoPermitido: ['visualizar', 'editar'],
  precoDescontoCampanha: ['visualizar', 'editar'],
  precoSyngentaClass: ['criar', 'visualizar', 'deletar', 'editar'],
  precoSyngentaDesconto: ['visualizar', 'editar'],
  medidaIR: ['visualizar', 'editar'],
  medidaPMS: ['visualizar', 'editar'],
  medidaPOP: ['visualizar', 'editar'],
  estoqueEstimativa: ['visualizar', 'editar'],
  estoqueCota: ['visualizar', 'editar'],
  estoqueCotaRegional: ['visualizar', 'editar'],
  sistemaProfissional: [
    'visualizar'
    // 'desbloquear'
    // 'criar',
    // 'deletar',
    // 'editar',
    // 'bloquear',
    // 'inativar'
  ],
  sistemaDocusign: ['visualizar', 'editar'],
  tsi: ['criar', 'visualizar', 'deletar', 'editar'],
  tsiAssociation: ['criar', 'visualizar', 'deletar', 'editar']
}

export const GerenteAdministracaoVendas = {
  cliente: ['criar', 'visualizar', 'deletar', 'editar'],
  pedido: [
    // Gerais
    'criar', // Simular
    'visualizar', // Gerenciar
    'cancelar', // Status até proposta
    'cancelarPedido', // Status todos status canceláveis
    'uploadDocumento',
    'deletarDocumento',
    'baixarDocumento',
    // Simulação
    'visualizarDocsCreditoSimulacao',
    'baixarSimulacao',
    'gerarProposta',
    // Proposta
    'baixarProposta',
    'aprovarProposta',
    'estenderPrazoProposta',
    // Pedido - Contrato
    'gerarContrato',
    'baixarContrato',
    'cancelarContrato',
    // Pedido - Pedido
    'baixarPedido',
    'finalizarPedido'
  ],
  budget: ['visualizar', 'deletar'],
  harvest: ['criar', 'visualizar', 'editar'],
  forecast: ['criar', 'visualizar', 'editar', 'deletar'],
  barter: ['criar', 'visualizar', 'editar', 'deletar'],
  period: ['criar', 'visualizar', 'editar', 'deletar'],
  regiaoFrete: ['criar', 'visualizar', 'deletar', 'editar'],
  regiaoMonsanto: ['criar', 'visualizar', 'deletar', 'editar'],
  carteira: ['criar', 'visualizar', 'deletar', 'editar', 'gerenciar'],
  precoTratamento: ['visualizar', 'editar'],
  precoTecnologia: ['visualizar', 'editar'],
  precoGermoplasma: ['visualizar', 'editar'],
  precoFrete: ['visualizar', 'editar'],
  precoDescontoPermitido: ['visualizar', 'editar'],
  precoDescontoCampanha: ['visualizar', 'editar'],
  precoSyngentaClass: ['criar', 'visualizar', 'deletar', 'editar'],
  precoSyngentaDesconto: ['visualizar', 'editar'],
  medidaIR: ['visualizar', 'editar'],
  medidaPMS: ['visualizar', 'editar'],
  medidaPOP: ['visualizar', 'editar'],
  estoqueEstimativa: ['visualizar', 'editar'],
  estoqueCota: ['visualizar', 'editar'],
  estoqueCotaRegional: ['visualizar', 'editar'],
  sistemaProfissional: [
    'visualizar'
    // 'criar',
    // 'deletar',
    // 'editar',
    // 'bloquear',
    // 'inativar'
  ],
  sistemaDocusign: ['visualizar', 'editar'],
  tsi: ['criar', 'visualizar', 'deletar', 'editar'],
  tsiAssociation: ['criar', 'visualizar', 'deletar', 'editar']
}

export const Administrador = {
  cliente: ['criar', 'visualizar', 'deletar', 'editar'],
  pedido: [
    // Gerais
    'criar', // Simular
    'visualizar', // Gerenciar
    'cancelar', // Status até proposta
    'cancelarPedido', // Status todos status canceláveis
    'cancelarPedidoSimulacao', // Status apenas da simulação
    'atualizarPrecoPedido', //Royalties
    'uploadDocumento',
    'deletarDocumento',
    'baixarDocumento',
    // Simulação
    'visualizarDocsCreditoSimulacao',
    'aprovarCreditoSimulacao',
    'aprovarPrecoSimulacao',
    'baixarSimulacao',
    'gerarProposta',
    'aprovarTipoDeVenda',
    // Proposta
    'baixarProposta',
    'aprovarProposta',
    'estenderPrazoProposta',
    // Pedido - Contrato
    'gerarContrato',
    'baixarContrato',
    'aprovarContrato',
    'cancelarContrato',
    // Pedido - Pedido
    'baixarPedido',
    'finalizarPedido'
  ],
  budget: ['visualizar', 'editar', 'deletar'],
  harvest: ['criar', 'visualizar', 'editar'],
  forecast: ['criar', 'visualizar', 'editar', 'deletar'],
  barter: ['criar', 'visualizar', 'editar', 'deletar'],
  period: ['criar', 'visualizar', 'editar', 'deletar'],
  regiaoFrete: ['criar', 'visualizar', 'deletar', 'editar'],
  regiaoMonsanto: ['criar', 'visualizar', 'deletar', 'editar'],
  carteira: ['criar', 'visualizar', 'deletar', 'editar', 'gerenciar'],
  precoTratamento: ['visualizar', 'editar'],
  precoTecnologia: ['visualizar', 'editar'],
  precoGermoplasma: ['visualizar', 'editar'],
  precoFrete: ['visualizar', 'editar'],
  precoDescontoPermitido: ['visualizar', 'editar'],
  precoDescontoCampanha: ['visualizar', 'editar'],
  precoSyngentaClass: ['criar', 'visualizar', 'deletar', 'editar'],
  precoSyngentaDesconto: ['visualizar', 'editar'],
  medidaIR: ['visualizar', 'editar'],
  medidaPMS: ['visualizar', 'editar'],
  medidaPOP: ['visualizar', 'editar'],
  estoqueEstimativa: ['visualizar', 'editar'],
  estoqueCota: ['visualizar', 'editar'],
  estoqueCotaRegional: ['visualizar', 'editar'],
  sistemaProfissional: [
    'criar',
    'visualizar',
    'deletar',
    'editar',
    'bloquear',
    'inativar',
    'invalido',
    'desbloquear'
  ],
  sistemaDocusign: ['visualizar', 'editar'],
  tsi: ['criar', 'visualizar', 'deletar', 'editar'],
  tsiAssociation: ['criar', 'visualizar', 'deletar', 'editar']
}

export const GlobalPermissions = {
  cliente: ['criar', 'visualizar', 'deletar', 'editar'],
  pedido: [
    // Gerais
    'criar', // Simular
    'visualizar', // Gerenciar
    'cancelar', // Status até proposta
    'cancelarPedido', // Status todos status canceláveis
    'cancelarPedidoSimulacao', // Status apenas da simulação
    'atualizarPrecoPedido', //Royalties
    'uploadDocumento',
    'deletarDocumento',
    'baixarDocumento',
    // Simulação
    'visualizarDocsCreditoSimulacao',
    'aprovarCreditoSimulacao',
    'aprovarPrecoSimulacao',
    'baixarSimulacao',
    'gerarProposta',
    'aprovarTipoDeVenda',
    // Proposta
    'baixarProposta',
    'aprovarProposta',
    'estenderPrazoProposta',
    // Pedido - Contrato
    'gerarContrato',
    'baixarContrato',
    'aprovarContrato',
    'cancelarContrato',
    // Pedido - Pedido
    'baixarPedido',
    'finalizarPedido'
  ],
  budget: ['visualizar', 'editar', 'deletar'],
  harvest: ['criar', 'visualizar', 'editar'],
  forecast: ['criar', 'visualizar', 'editar', 'deletar'],
  barter: ['criar', 'visualizar', 'editar', 'deletar'],
  period: ['criar', 'visualizar', 'editar', 'deletar'],
  regiaoFrete: ['criar', 'visualizar', 'deletar', 'editar'],
  regiaoMonsanto: ['criar', 'visualizar', 'deletar', 'editar'],
  carteira: ['criar', 'visualizar', 'deletar', 'editar', 'gerenciar'],
  precoTratamento: ['visualizar', 'editar'],
  precoTecnologia: ['visualizar', 'editar'],
  precoGermoplasma: ['visualizar', 'editar'],
  precoFrete: ['visualizar', 'editar'],
  precoDescontoPermitido: ['visualizar', 'editar'],
  precoDescontoCampanha: ['visualizar', 'editar'],
  precoSyngentaClass: ['criar', 'visualizar', 'deletar', 'editar'],
  precoSyngentaDesconto: ['visualizar', 'editar'],
  medidaIR: ['visualizar', 'editar'],
  medidaPMS: ['visualizar', 'editar'],
  medidaPOP: ['visualizar', 'editar'],
  estoqueEstimativa: ['visualizar', 'editar'],
  estoqueCota: ['visualizar', 'editar'],
  estoqueCotaRegional: ['visualizar', 'editar'],
  sistemaProfissional: [
    'criar',
    'visualizar',
    'deletar',
    'editar',
    'bloquear',
    'inativar',
    'invalido',
    'desbloquear'
  ],
  sistemaDocusign: ['visualizar', 'editar'],
  tsi: ['criar', 'visualizar', 'deletar', 'editar'],
  tsiAssociation: ['criar', 'visualizar', 'deletar', 'editar']
}

export type CommonKeys =
  | 'criar'
  | 'visualizar'
  | 'deletar'
  | 'editar'
  | 'cancelar'
  | 'cancelarPedido'
  | 'cancelarPedidoSimulacao'
  | 'atualizarPrecoPedido'
  | 'uploadDocumento'
  | 'deletarDocumento'
  | 'baixarDocumento'
  | 'visualizarDocsCreditoSimulacao'
  | 'aprovarCreditoSimulacao'
  | 'aprovarTipoDeVenda'
  | 'aprovarPrecoSimulacao'
  | 'baixarSimulacao'
  | 'gerarProposta'
  | 'baixarProposta'
  | 'aprovarProposta'
  | 'estenderPrazoProposta'
  | 'gerarContrato'
  | 'baixarContrato'
  | 'aprovarContrato'
  | 'cancelarContrato'
  | 'baixarPedido'
  | 'finalizarPedido'
  | 'bloquear'
  | 'inativar'
  | 'invalido'
  | 'gerenciar'
  | 'desbloquear'

export default {
  RepresentanteVenda,
  Gerente,
  Agente,
  Financeiro,
  AnalistaFinanceiro,
  AnalistaFinanceiroComLimite,
  Diretor,
  Superintendente,
  InteligenciaMercado,
  AdministracaoVendas,
  GerenteAdministracaoVendas,
  Administrador
} as any
