import React from 'react'
import { AccessPolice, IAppRoute } from './interfaces/IAppRoute'

export const routes: IAppRoute[] = [
  {
    name: 'login',
    path: '/login',
    acessPolice: AccessPolice.GuestOnly,
    component: React.lazy(() => import('./screens/login/Login'))
  },
  {
    name: 'recovery',
    path: '/recover',
    acessPolice: AccessPolice.GuestOnly,
    component: React.lazy(() => import('./screens/recovery/Recovery'))
  },
  {
    name: 'expired',
    path: '/expired',
    acessPolice: AccessPolice.ExpiredOnly,
    component: React.lazy(() => import('./screens/expired/Expired'))
  },
  {
    name: 'logout',
    path: '/profile/logout',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/profile/Logout'))
  },
  {
    name: 'profile',
    path: '/profile/edit',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/profile/ProfileEdit'))
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/dashboard/Dashboard'))
  },
  {
    name: 'clients',
    path: '/clientes',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/clients/ClientIndex'))
  },
  {
    name: 'clients.create',
    path: '/novo',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/clients/ClientCreate'))
  },
  {
    name: 'clients.edit',
    path: '/:id/editar/',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/clients/ClientEdit'))
  },
  {
    name: 'forecast',
    path: '/forecast?:harvestId&:clientId&:periodId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/forecast/ForecastIndex'))
  },
  {
    name: 'barter',
    path: '/barter',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/barter/BarterIndex'))
  },
  {
    name: 'harvest',
    path: '/safra',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/harvest/HarvestIndex')),
    children: [
      {
        name: 'create',
        path: '/novo',
        component: React.lazy(() => import('./screens/harvest/HarvestCreate'))
      },
      {
        name: 'edit',
        path: '/:id/editar',
        component: React.lazy(() => import('./screens/harvest/HarvestEdit'))
      }
    ]
  },
  {
    name: 'period',
    path: '/periodo',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/period/PeriodIndex')),
    children: [
      {
        name: 'create',
        path: '/novo',
        component: React.lazy(() => import('./screens/period/PeriodCreate'))
      },
      {
        name: 'edit',
        path: '/:id/editar',
        component: React.lazy(() => import('./screens/period/PeriodEdit'))
      }
    ]
  },
  {
    name: 'regions',
    path: '/regioes/frete',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/Region/RegionIndex')),
    children: [
      {
        name: 'create',
        path: '/novo',
        component: React.lazy(() => import('./screens/Region/RegionCreate'))
      },
      {
        name: 'edit',
        path: '/:id/editar',
        component: React.lazy(() => import('./screens/Region/RegionEdit'))
      }
    ]
  },
  {
    name: 'vendor-region',
    path: '/regioes/monsanto',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/Region/VendorRegion/VendorRegionIndex')
    ),
    children: [
      {
        name: 'create',
        path: '/novo',
        component: React.lazy(() =>
          import('./screens/Region/VendorRegion/VendorRegionCreate')
        )
      },
      {
        name: 'edit',
        path: '/:id/editar',
        component: React.lazy(() =>
          import('./screens/Region/VendorRegion/VendorRegionEdit')
        )
      }
    ]
  },
  {
    name: 'manage-wallet',
    path: '/carteira/gerenciar',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/wallet/WalletIndex')),
    children: [
      {
        name: 'create',
        path: '/novo',
        component: React.lazy(() => import('./screens/wallet/WalletCreate'))
      },
      {
        name: 'edit',
        path: '/:id/editar',
        component: React.lazy(() => import('./screens/wallet/WalletEdit'))
      }
    ]
  },
  {
    name: 'view-wallet',
    path: '/carteira/visualizar',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/wallet/wallet-rtv/WalletRtvIndex')
    )
  },
  {
    name: 'tsi-association',
    path: '/tsi/associacao',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/tsi-association/TsiAssociationIndex')
    )
  },
  {
    name: 'tsi-prices',
    path: '/precos/tsi?:treatmentId&:harvestId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/tsi-price/TsiPriceIndex'))
  },
  {
    name: 'tsi-class-prices',
    path: '/precos/class-tsi?:classId&:harvestId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/tsi-class-price/TsiPriceIndex')
    )
  },
  {
    name: 'tsi-product-prices',
    path: '/precos/produto-tsi?:productId&:harvestId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/tsi-product-price/TsiPriceIndex')
    )
  },
  {
    name: 'tech-prices',
    path: '/precos/tecnologia?:technologyId&:harvestId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/tech-price/TechPriceIndex'))
  },
  {
    name: 'germo-prices',
    path: '/precos/germoplasma?:cultiveId&:harvestId&:regionId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/germo-price/GermoPriceIndex'))
  },
  {
    name: 'freight-prices',
    path: '/precos/frete?:regionId&:harvestId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/freight-price/FreightPriceIndex')
    )
  },
  {
    name: 'max-discount',
    path: '/precos/desconto-permitido?:harvestId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/max-discount/MaxDiscountIndex')
    )
  },
  {
    name: 'seasonal-discount',
    path: '/precos/campanha-desconto?:cultiveId&:harvestId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/seasonal-discount/SeasonalDiscountIndex')
    )
  },
  {
    name: 'syngenta-classification',
    path: '/precos/classificacao-syngenta',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/syngenta-classification/SyngentaClassificationIndex')
    ),
    children: [
      {
        name: 'create',
        path: '/novo',
        component: React.lazy(() =>
          import(
            './screens/syngenta-classification/SyngentaClassificationCreate'
          )
        )
      },
      {
        name: 'edit',
        path: '/:id/editar',
        component: React.lazy(() =>
          import('./screens/syngenta-classification/SyngentaClassificationEdit')
        )
      }
    ]
  },
  {
    name: 'syngenta-discount',
    path: '/precos/desconto-tsi-syngenta?:clientId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/syngenta-discount/SyngentaDiscountIndex')
    )
  },
  {
    name: 'recomendation-rate',
    path: '/medidas/indice-recomendacao?:cultiveId&:harvestId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/recomendation-rate/RecomendationRateIndex')
    )
  },
  {
    name: 'pms',
    path: '/medidas/peso-mil-sementes?:cultiveId&:harvestId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/pms/PmsIndex'))
  },
  {
    name: 'pop',
    path: '/medidas/populacao-regiao?:cultiveId&:harvestId&:regionTypeId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/population/PopulationIndex'))
  },
  {
    name: 'estimate',
    path: '/estoque/estimativa?:cultiveId&:harvestId',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/StockEstimate/EstimateIndex'))
  },
  {
    name: 'quota-region',
    path: '/estoque/cotasregionais?:harvestId&:cultiveId&:refresh',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/stock-quota-region/QuotaIndex')
    )
  },
  {
    name: 'quota',
    path: '/estoque/cotas?:harvestId&:cultiveId&:refresh',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/stock-quota/QuotaIndex'))
  },
  {
    name: 'order-simulator',
    path: '/pedidos/simulador?:budgetCode',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/Simulator/SimulatorIndex'))
  },
  {
    name: 'order-simulator-edit',
    path: '/pedidos/simulador/:id/editar',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/Simulator/SimulatorIndex'))
  },
  {
    name: 'order-simulator-result',
    path: '/pedidos/simulador/result/:id',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/Simulator/SimulationResult'))
  },
  {
    name: 'order',
    path: '/pedidos',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/Order/OrderIndex')),
    children: [
      {
        name: 'show',
        path: '/:id',
        component: React.lazy(() => import('./screens/Order/OrderShow'))
      }
    ]
  },
  {
    name: 'budget',
    path: '/orcamentos',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() => import('./screens/budget/BudgetIndex')),
    children: [
      {
        name: 'show',
        path: '/:id',
        component: React.lazy(() => import('./screens/budget/BudgetShow'))
      }
    ]
  },
  {
    name: 'professional',
    path: '/profissional',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/professional/ProfessionalIndex')
    ),
    children: [
      {
        name: 'create',
        path: '/novo',
        component: React.lazy(() =>
          import('./screens/professional/ProfessionalCreate')
        )
      },
      {
        name: 'edit',
        path: '/:id/editar',
        component: React.lazy(() =>
          import('./screens/professional/ProfessionalEdit')
        )
      }
    ]
  },
  {
    name: 'docusign',
    path: '/parametros/docusign/',
    acessPolice: AccessPolice.AuthOnly,
    component: React.lazy(() =>
      import('./screens/DocusignParams/DocusignParamsIndex')
    )
  }
]
