import { FunctionComponent, LazyExoticComponent } from 'react'
import { Route } from 'router5'

export enum AccessPolice {
  GuestOnly,
  AuthOnly,
  ExpiredOnly,
  None
}

export interface IAppRoute extends Route {
  component?: LazyExoticComponent<FunctionComponent>
  acessPolice?: AccessPolice
  children?: IAppRoute[]
}
