import { action, observable } from 'mobx'
import { ApiError } from '../shared/ApiError'
import {
  trySendResetCode,
  tryVerifyResetCode,
  tryRecoverPassword,
  tryUpdatePassword
} from '../shared/ServerApi'

export class PasswordStore {
  @observable
  isLoading = false

  @observable
  email = ''

  @observable
  resetToken = ''

  @observable
  hasError = false

  @observable
  error?: ApiError

  @action.bound
  setEmail(email: string) {
    this.email = email
  }

  @action.bound
  async trySendResetCode(recaptcha: string) {
    this.hasError = false
    this.error = undefined

    try {
      this.isLoading = true
      await trySendResetCode(this.email, recaptcha)
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) this.error = e
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async tryVerifyResetCode(code: string) {
    this.hasError = false
    this.error = undefined

    try {
      this.isLoading = true
      const response = await tryVerifyResetCode(this.email, code)

      if (response && response.tokenResetAccount) {
        this.resetToken = response.tokenResetAccount
      }
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) this.error = e
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async tryRecoverPassword(novaSenha: string) {
    this.hasError = false
    this.error = undefined

    try {
      this.isLoading = true
      await tryRecoverPassword(this.email, this.resetToken, novaSenha)
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) this.error = e
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async updatePassword(newPassword: string, oldPassword: string) {
    this.hasError = false
    this.error = undefined

    try {
      this.isLoading = true
      await tryUpdatePassword(newPassword, oldPassword)
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) this.error = e
      throw e
    } finally {
      this.isLoading = false
    }
  }
}
