import { Route } from 'router5'
import { AccessPolice, IAppRoute } from '../interfaces/IAppRoute'
import getStore from './getStore'

function redirectTo(routeName: string) {
  return Promise.reject({ redirect: { name: routeName } })
}

/**
 * Transforma IAppRoute para IRoute
 */
function transformAppRouteToRoute({
  acessPolice,
  name,
  path,
  children
}: IAppRoute) {
  function canActivate() {
    const { isAuth, isGuest, isExpired } = getStore('auth')
    const isGuestOnly = acessPolice === AccessPolice.GuestOnly

    if (isExpired) {
      if (acessPolice === AccessPolice.ExpiredOnly) return true

      return redirectTo('expired')
    }

    if (isGuestOnly) return isGuest || redirectTo('dashboard')

    return isAuth || redirectTo('login')
  }

  const hasAccessPolice = acessPolice && acessPolice !== AccessPolice.None

  const route: Route = {
    name,
    path
  }
  if (hasAccessPolice) route.canActivate = () => canActivate
  if (children) route.children = children.map(transformAppRouteToRoute)

  return route
}

export function createRoutes(routes: IAppRoute[]): Route[] {
  return routes.map(transformAppRouteToRoute)
}
