import { action, observable } from 'mobx'
import { IContact, IContactData } from '../interfaces/IContact'
import { ApiError } from '../shared/ApiError'
import {
  tryDeleteContact,
  tryGetContacts,
  tryPostContact,
  tryPutContact
} from '../shared/ServerApi'

export class ContactStore {
  // Pagination
  @observable page = 1
  @observable pageSize = 10
  @observable total = 0

  // States
  @observable isLoading = false
  @observable hasError = false
  @observable error?: ApiError

  // Items
  @observable contacts: IContact[] = []

  @action.bound setPage(page: number) {
    this.page = page
  }

  @action.bound setPageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @action.bound async fetchContacts(clientId: number) {
    try {
      this.isLoading = true
      const response = await tryGetContacts(clientId, this.page, this.pageSize)

      this.total = response.totalItemCount
      this.contacts = response.result
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) this.error = e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveContact(contact: IContactData) {
    this.hasError = false
    this.error = undefined
    this.isLoading = true

    try {
      const response = contact.id
        ? await tryPutContact(contact.id, contact)
        : await tryPostContact(contact)

      return response
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) this.error = e
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async deleteContact(contactId: number) {
    this.isLoading = true
    this.error = undefined

    try {
      await tryDeleteContact(contactId)
    } catch (e) {
      this.hasError = true
      if (e instanceof ApiError) this.error = e
    } finally {
      this.isLoading = false
    }
  }
}
